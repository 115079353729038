import { template as template_8fa8472824244005a2a02b4b136b05c3 } from "@ember/template-compiler";
const WelcomeHeader = template_8fa8472824244005a2a02b4b136b05c3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
