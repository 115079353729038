import { template as template_abf266a6399f46bb9f323e2f120d7e15 } from "@ember/template-compiler";
const FKControlMenuContainer = template_abf266a6399f46bb9f323e2f120d7e15(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
