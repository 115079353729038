import { template as template_4cf91d313d7b42329dfa6dac6b7512f7 } from "@ember/template-compiler";
const FKText = template_4cf91d313d7b42329dfa6dac6b7512f7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
